import { authAdmin } from '../middleware/isAuth';
import VueRouter from 'vue-router';

const routeMain = new VueRouter({
   mode: 'history',
   routes: [
/*       {
         path: '/',
         name: 'home',
         component: () => import('../../views/TheHome.vue'),
      },
      {
         path: '/ui-kit',
         name: 'ui-kit',
         component: () => import('../../views/TheUI.vue'),
      }, */
      {
         path: '/',
         name: 'home',
         redirect: '/admin',
      },
      {
         path: '/admin',
         name: 'admin',
         redirect: { name: 'admin-login' },
         component: () => import('../../views/AdminPanel/AdminPanel.vue'),
         children: [
            {
               path: 'login',
               name: 'admin-login',
               component: () => import('../../views/AdminPanel/AdminPanelLogin.vue'),
            },
            {
               path: 'dashboard',
               name: 'admin-dashboard',
               redirect: {
                  name: 'dashboard-item',
                  params: {
                     name: 'home',
                  },
               },

               component: () => import('../../views/AdminPanel/AdminPanelDashboard.vue'),
               children: [
                  {
                     path: '/admin/dashboard/:name',
                     name: 'dashboard-item',
                     component: () => import('../../views/AdminPanel/AdminPanelDasboardItem.vue'),
                     meta: {
                        middleware: [authAdmin],
                     },
                  },
               ],
            },
         ],
      },
      {
         path: '/*',
         name: '404',
         component: () => import('../../views/The404Page.vue'),
      }, 
/*       {
         path: '/main',
         name: 'main',
         component: () => import('../../views/TheHomeSecond.vue'),
      },
      {
         path: '/quiz',
         name: 'quiz',
         component: () => import('../../views/TheQuiz.vue'),
      },
      {
         path: '/quizs',
         name: 'quizs',
         component: () => import('../../views/TheQuizSec.vue'),
      },
      {
         path: '/results',
         name: 'results',
         component: () => import('../../views/TheResults.vue'),
      },
      {
         path: '/login',
         name: 'login',
         component: () => import('../../views/TheLogin.vue'),
         children: [
            {
               path: 'name',
               name: 'name',
               component: () => import('../../views/ContactsName.vue'),
            },
            {
               path: 'register',
               name: 'register',
               component: () => import('../../views/ContactsRegister.vue'),
            },
         ],
      }, */
     /*  {
         path: '/auth/google',
         name: 'auth-google',
         component: () => import('../../views/TheAuthGoogle.vue'),
      },
      {
         path: '/auth/facebook',
         name: 'auth-facebook',
         component: () => import('../../views/TheAuthFacebook.vue'),
      },
      {
         path: '/auth/twitter',
         name: 'auth-twitter',
         component: () => import('../../views/TheAuthTwitter.vue'),
      },
      {
         path: '/privacy-policy',
         name: 'privacy-policy',
         component: () => import('../../views/ThePrivacy'),
      },
      {
         path: '/terms-of-service',
         name: 'terms-of-service',
         component: () => import('../../views/TheTerms'),
      },
      {
         path: '/registration',
         name: 'registration',
         component: () => import('../../views/TheRegistration.vue'),
      },
      {
         path: '/confirmemail',
         name: 'confirmemail',
         component: () => import('../../views/TheConfirmemail.vue'),
      },
      {
         path: '/registerphone',
         name: 'registerphone',
         component: () => import('../../views/TheRegisterPhone.vue'),
      },

      {
         path: '/*',
         name: '404',
         component: () => import('../../views/The404Page.vue'),
      }, */
   ],
});

export default routeMain;
