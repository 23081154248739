import axios from 'axios';
//import router from '../router/router.js';

const http = axios.create({
  // baseURL: 'http://localhost:3000/',
   baseURL: 'https://api.sleep-well.coach',
});

http.interceptors.request.use(
   function (config) {
      config.headers.Authorization = 'Bearer ' + localStorage.user_token;
      if (window.location.pathname.match('admin') && sessionStorage.token) {
         config.headers.Authorization = 'Bearer ' + sessionStorage.token;
      }
      return config;
   },
   function (error) {
      return Promise.reject(error);
   },
);

/* http.interceptors.response.use(
   res => {
      return res;
   },
   err => {
      if (err?.response?.data?.message) {
         if (err.response.status === 401) {
            router.push({
               name: 'home',
            });
         } else {
            return Promise.reject(err.response);
         }
      }
      return Promise.reject(err);
   },
);*/

export default http;
