import Vue from 'vue';
import App from './App.vue';
import router from './router/router.js';
import store from './store/store.js';
import http from './plugins/http.js';
import { i18n } from './i18n';
import './sass/vuetiftyColor.scss';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;
Vue.prototype.$http = http;
Vue.use(i18n);

new Vue({
   router,
   store,
   vuetify,
   i18n,
   render: h => h(App),
}).$mount('#app');
